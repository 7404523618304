import api from '@/base/utils/request'

export const getListData = (data) => {
  return api({
    url: '/admin/college/AudioComment/index',
    method: 'post',
    data,
  })
}

export const softDelete = (data) => {
  return api({
    url: '/admin/college/AudioComment/softDelete',
    method: 'post',
    data,
  })
}

export const putBack = (data) => {
  return api({
    url: '/admin/college/AudioComment/putBack',
    method: 'post',
    data,
  })
}

export const trueDelete = (data) => {
  return api({
    url: '/admin/college/AudioComment/delete',
    method: 'post',
    data,
  })
}

export const checkAudioComment = (data) => {
  return api({
    url: '/admin/college/AudioComment/check',
    method: 'post',
    data,
    notCancel: true,
  })
}

export const uncheckAudioComment = (data) => {
  return api({
    url: '/admin/college/AudioComment/uncheck',
    method: 'post',
    data,
    notCancel: true,
  })
}

export const getCommentDetails = (data) => {
  return api({url:'/admin/college/AudioComment/detail', method: 'post', data})
}